<template>
    <div>
        <div class="SecondTitle">
            <span>
                {{ $t("enterNumber.secondTitle") }}
            </span>
        </div>
        <div class="tips">
            <span>
                {{ $t("enterNumber.tips") }}
            </span>
        </div>

        <van-form :onChange="saveInfo()">
            <div style="margin-top: 15px">
                <div style="width:25%;display: inline-block;vertical-align: middle;margin: 0 auto">
                    <van-image style="vertical-align: middle;float: left" :src="imagePath" />
                </div>
                <div style="width:5%;display: inline-block;vertical-align: middle;margin: 0 auto">
                    <div style="width: 1px;float:right;height: 20px; background: #000000;"></div>
                </div>
                <div style="width:70%;display: inline-block;vertical-align: middle;margin: 0 auto">
                    <van-field type="number" v-model="phoneNumber" center
                        :placeholder="this.$t('enterNumber.phonePlaceHolder')" :maxlength="9"
                        :rules="[{ pattern, trigger: 'onBlur', message: this.$t('enterNumber.phoneRuleMessage') }]" />
                    <van-number-keyboard v-model="phoneNumber" :show="showKeyboard" @blur="showKeyboard = false" />
                </div>
            </div>

            <van-divider :style="{ borderColor: '#000000', margin: '0' }" />
            <div class="small-tips">
                <span>
                    {{ $t("enterNumber.smallTips") }}
                </span>
            </div>
            <div class="second-tips">
                <div>
                    <input class="agreeCheckBox" type="checkbox" v-model="agreeChecked">
                </div>
                <div v-if="brandConfig.name == 'tomford' || brandConfig.name == 'lelabo'" class="agreeStatement"
                    v-html='$t("enterNumber.readPrivacyPolicyForTFAndLL").format(brandConfig.display, privacyPolicyLink)'>
                </div>
                <div v-else class="agreeStatement"
                    v-html='$t("enterNumber.readPrivacyPolicy").format(brandConfig.display, privacyPolicyLink)'>
                </div>
            </div>
            <van-button :disabled=checkPhone() size="large" round native-type="submit" class="button-getotp" @click="toOtp">
                {{ $t("enterNumber.getOTP") }}
            </van-button>
        </van-form>
    </div>
</template>

<script>
import {
    Form,
    DropdownMenu,
    DropdownItem,
    Divider,
    Button,
    Field,
    NumberKeyboard,
    PasswordInput,
    Toast
} from 'vant';
import { Image as VanImage } from 'vant';
import { generateOtp } from '@/api/eformApi'
export default {
    name: "EnterNumber",
    components: {
        [Form.name]: Form,
        [NumberKeyboard.name]: NumberKeyboard,
        [PasswordInput.name]: PasswordInput,
        [VanImage.name]: VanImage,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Divider.name]: Divider,
        [Button.name]: Button,
        [Field.name]: Field
    },
    props: {
        brandConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    created() {
        this.imagePath = require('/public/img/' + 'th' + '-areacode.png')
        console.log('enasd', this.$root.$i18n.locale)
        this.$root.$i18n.locale = this.$route.params.lang
        this.countryPath = this.$route.params.countryPath

        switch (this.$route.params.countryPath) {
            case 'sg':
                this.areaCode = 65
                this.pattern = this.sgPattern
                break;
            case 'TH':
                this.areaCode = 66
                this.pattern = this.thPattern
                break;
            default:
                this.areaCode = 66
                this.pattern = this.myPattern
                break;
        }
        if (localStorage.getItem('enterNumberInfo')) {
            this.phoneNumber = localStorage.getItem('enterNumberInfo')
        }
    },
    data() {
        return {
            phoneNumber: '',
            linkToken: '',
            showKeyboard: false,
            imagePath: '',
            pattern: /^$|^([689]{1})+([0-9]{7})$/,
            sgPattern: /^$|^([689]{1})+([0-9]{7})+$/,
            thPattern: /^$|^([0-9]{9})+$/,
            myPattern: /^$|^([0-9]{10})+$/,
            areaCode: 66,
            agreeChecked: false,
            brandName: '',
            country: '',
            countryPath: ''
        };
    },
    computed: {
        privacyPolicyLink() {
            if (this.brandConfig.privacyPolicy) {
                return `<span><a href="${this.brandConfig.privacyPolicy}">Privacy Policy</a></span>`;
            } else {
                return `<span>Privacy Policy</span>`;
            }
        }
    },
    mounted() {
    },
    methods: {
        toOtp() {
            sessionStorage.setItem('phoneNumber', this.areaCode + this.phoneNumber)
            console.log(this.$route.params.countryPath)
            generateOtp({
                // TODO: replace with real phone number
                phoneno: this.areaCode + this.phoneNumber,
                country: this.$route.params.countryPath,
                brandName: this.brandConfig.name,
                brandAbbr: this.brandConfig.abbr,
            }).then((res) => {
                if (res.success && res.data.request_id) {
                    console.log(res.data.request_id)
                    if (this.countryPath == 'TH') {
                        console.log(res.data.request_id)
                        sessionStorage.setItem('th_request_id', res.data.request_id)
                    }
                    Toast.success(this.$t('enterNumber.toastSuccess'))
                    this.$router.push({
                        name: 'EnterOpt',
                        params: {
                            //TODO: replace with real phone number
                            phoneNumber: this.areaCode + this.phoneNumber,
                            countryPath: this.$route.params.countryPath,
                            brandName: this.brandConfig.name,
                            otpcode: res.data.ref_code,
                            lang: this.$route.params.lang,
                            storeId: this.$route.query.storeId
                        },
                        query: {
                            phoneNumber: this.areaCode + this.phoneNumber,
                            storeId: this.$route.query.storeId,
                        }
                    })
                } else {
                    console.log(res.success)
                    // Toast.fail(res.data)
                    Toast.fail(this.$t('enterNumber.toastFail'))
                    //TODO: mock need to be deleted
                    // this.$router.push({
                    //     name: 'EnterOpt',
                    //     params: {
                    //         phoneNumber: this.areaCode+this.phoneNumber,
                    //         country: localStorage.getItem('countryPath')
                    //     }
                    // })
                }
            })
            // Toast.success(this.$t('enterNumber.toastSuccess'))
            // this.$router.push({
            //     name: 'EnterOpt',
            //     params: {
            //         //TODO: replace with real phone number
            //         phoneNumber: this.areaCode + this.phoneNumber,
            //         countryPath: this.$route.params.countryPath,
            //         brand: this.$route.params.brand,
            //         lang: this.$route.params.lang,
            //         storeId: this.$route.query.storeId
            //     },
            //     query: {
            //         phoneNumber: this.areaCode + this.phoneNumber,
            
            //     }
            // })
        },
        checkPhone() {
            switch (this.$route.params.countryPath) {
                case 'sg':
                    if (this.phoneNumber.length === 0 || !this.sgPattern.test(this.phoneNumber) || !this.agreeChecked) {
                        return true
                    } else {
                        return false
                    }
                case 'TH':
                    if (this.phoneNumber.length === 0 || !this.thPattern.test(this.phoneNumber) || !this.agreeChecked) {
                        return true
                    } else {
                        return false
                    }
                default:
                    return true
            }
        },
        saveInfo() {
            console.log(this.phoneNumber)
            localStorage.setItem('enterNumberInfo', this.phoneNumber)
        },
    }
}
</script>

<style scoped>
* {
    font-family: Helvetica-Regular !important;
}

>>>.van-popup--top {
    width: 25%;
    padding-left: 16px
}

.button-getotp {
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 4%;
}

>>>.van-password-input__item {
    border-radius: 5px
}
</style>
